import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-prevantivno-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-prevantivno-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-prevantivno-header.jpg"
import LuteinComplexXL from "./../../images/lutein-prevantivno-header.jpg"

import Lutein from "./../../images/lutein-prevantivno-Lutein.jpg"
import Bilberry from "./../../images/lutein-prevantivno-borovinki-bilberry.jpg"
import Carotenoids from "./../../images/lutein-prevantivno-carotenoids.jpg"
import Corn from "./../../images/lutein-prevantivno-corn.jpg"


const LuteinPrevantivno = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-prevantivno" }}>
    <Seo title="Лутеин, зеаксантин и черна боровинка за превантивно очно здраве" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ЛУТЕИН, ЗЕАКСАНТИН И ЧЕРНА БОРОВИНКА ...
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Лутеин, зеаксантин и черна боровинка за превантивно очно здраве"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Лутеин, зеаксантин и черна боровинка за превантивно очно здраве
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>Макулна дегенерация</h4>
          <p>
            Преди да обсъждаме лутеинa, зеаксантинa и черната боровинка, трябва
            да имаме основно разбиране какво е макулната дегенерация (МД).
          </p>
          <p>
            Макулна дегенерация е медицинско състояние засягащо очите. Случва
            се, когато светлоусещащите клетки в макулата се повредят и спрат да
            работят. То е основната причина за слепота по света за хора над 50
            годишна възраст и поради това се нарича „възрастово-свързана макулна
            дегенерация” (ВСМД).
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>Оксидативния стрес и очите</h4>
          <p>
            Тялото постоянно взаимодейства с кислорода като част от процеса за
            производство на енергия и вследствие на това, то произвежда
            високореагиращи молекули познати като свободни радикали. Те
            взаимодействат с другите молекули в клетката, което може да нанесе
            оксидативни повреди на протеините, мембраните и гените. Този тип
            щети участват в причиняването на някои болести и е почти сигурно, че
            имат ефект върху процеса на стареене. Тъй като защитните клетки не
            се възстановяват от самосебеси, тази част от окото, която е
            най-чувствителна към оксидативни щети, е лещата. Когато епителните
            клетки в основата са изложени на реактивен кислород, протеините в
            лещата се свързват на групи и така се формират катаракти.
          </p>
          <p>
            Ретината използва повече кислород от всяка друга тъкан в тялото и е
            податлива на щети. Полиненаситените мастни киселини намиращи се в
            лещата и ретината лесно биват засегнати от оксидация, в резултат на
            което се наблюдава деградация във функционирането и структурата на
            окото. Освен от метаболитна оксидация, друг източник на свободни
            радикали е замърсяването и разбира се, цигарения дим. Избягвайки ги
            възможно най-много, само би помогнало на очите ви.
          </p>
          <p>
            Експертите предупреждават, че ВСМД също така може да бъде причинена
            от прекалено излагане на слънчева светлина (особено
            високо-енергийната синя светлина). Затова е много важно да се носят
            слънчеви очила с качествени лещи, най-вече на места като ски курорти
            и плажове, където е много силно изложението под слънчевите лъчи.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h4>Лутеин</h4>
          <p>
            Има ли още нещо, което може да помогне на здравето на очите ни? Да,
            всъщност има. Нарича се лутеин. Пигмента лутеин (от латински
            “lutea”, означаващо „жълто”) е един от над 600-те вида естествено
            появяващи се каротеноиди. Намира се в царевица, яйчен жълтък и други
            жълти и зелени плодове и зеленчуци, но също така и в някой очни
            тъкани, конкретно в пигмента на ретината и части от лещата.
          </p>
          <p>
            Лутеинът има роля да забавя възрастово-свързаните деградации на тези
            тъкани, както като антиоксидант, така и индиректно, абсорбирайки
            синята светлина. Всъщност, различни проучвания показват, че
            съществува директна връзка между приемането на лутеин и
            пигментацията на окото и изследвания показват, че може да намали
            интензитета на синята светлина до 90%. Това е едно от тайните
            оръжия, които растенията използват за да се защитят от слънцето.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={Lutein} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={5}>
          <Image className="w-100" src={Bilberry} />
        </Col>
        <Col sm={7}>
          <h4>Черна боровинка</h4>
          <p>
            Още един превъзходен антиоксидант ценен особено от очите ви е
            Vaccinium myrtillus, повече познат като черна боровинка. Храстите
            черна боровинка растат в умерените климатични пояси по света и
            произвеждат плод, който се консумира пресен или се ползва за
            производство на десерти, конфитюри и напитки. Листата им са
            използвани през историята за лечение на много гастро-чревни
            проблеми.
          </p>
          <p>
            Една особено важна положителна страна на похапването на черни
            боровинки, е че те се смятат за подобряващи нощното зрение. Според
            слухове Кралските Военновъздушни Сили през Втората световна война са
            ги използвали точно поради тази причина. Изследвания показват, че те
            също биха могли да намалят или противодействат на ефекта от
            макулната дегенерация, вероятно заради въздействието на
            антиоксидантните им химикали (наричани антоцианидин флавоноиди)
            върху капилярите.
          </p>
          <p>
            Антоцианидин-флавоноидните съединения са производни на пигметите,
            които създават сините, виолетови и червени цветове у цветята и
            плодовете. Поне 15 различни вариации са идентифицирани в екстрактите
            от черна боровинка, което означава, че хранителните добавки с черна
            боровинка могат да доставят мощна доза от съединенията точно, където
            са най-необходими: във вашите очи.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h4>Зеаксантин</h4>
          <p>
            Освен лутеина и черната боровинка, още една отлична „добавка за очи”
            е зеаксантина. Той е един от най-често срещаните каротеноиди срещани
            в природата и е пигмента, който дава на шафрана, царевицата и други
            жълти растения техния характерен цвят. Което е по-важно, зеаксантина
            е един от двата каротеноида съдържащ се в ретината (другия е лутеин,
            както разбрахме по-рано).
          </p>
          <p>
            Експерименти са показали, че ниски нива на зеаксантин могат да имат
            пагубен ефект върху окото, по същия начин, както би могла и липсата
            на лутеин. Поради тази причина, проучванията подкрепят взимането на
            добавки с лутеин и/или зеаксантин за предотвратяване на ВСМД. Има
            също голямо количество доказателства, че повишаването на приема на
            лутеин и зеаксантин ще намали риска от създаване на катаракти.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={Corn} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={5}>
          <Image className="w-100" src={Carotenoids} />
        </Col>
        <Col sm={7}>
          <h4>
            Комбинация от високи дози хранителни вещества за вашето очно здраве
          </h4>
          <p>
            Освен от лутеин, зеаксантин и черна боровинка, Проучването на
            Възрастово-Свързани Очни Заболявания (клинично изследване
            спонсорирано от Националния Здравен Институт на САЩ) показва, че
            комбинация от високи дози бета-каротен, цинк, витамин Е и витамин C,
            може да намали риска от създаване на напреднала ВСМД от около 25%
          </p>
          <p>
            Получавате само един чифт очи: мъдро би било да се грижите за тях
            възможно най-добре, докато все още имате тази възможност.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinPrevantivno
